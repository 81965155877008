"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DCRoles = exports.DCRole = exports.CMRoles = exports.CMRole = exports.SSORoles = exports.SSORole = void 0;
var SSORole;
(function (SSORole) {
    SSORole["ADMIN"] = "admin";
    SSORole["USER"] = "user";
})(SSORole = exports.SSORole || (exports.SSORole = {}));
;
exports.SSORoles = [
    SSORole.ADMIN,
    SSORole.USER,
];
var CMRole;
(function (CMRole) {
    CMRole["ADMIN"] = "cm:admin";
    CMRole["AGENT_MANAGER"] = "cm:agentManager";
    CMRole["AGENT"] = "cm:agent";
    CMRole["AGENT_SUPPORT"] = "cm:agentSupport";
})(CMRole = exports.CMRole || (exports.CMRole = {}));
exports.CMRoles = [
    CMRole.ADMIN,
    CMRole.AGENT_MANAGER,
    CMRole.AGENT,
    CMRole.AGENT_SUPPORT,
];
var DCRole;
(function (DCRole) {
    DCRole["ADMIN"] = "dc:admin";
})(DCRole = exports.DCRole || (exports.DCRole = {}));
exports.DCRoles = [
    DCRole.ADMIN,
];
