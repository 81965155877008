import { FC, PropsWithChildren } from "react";
import { Box } from "@mui/material";
import {
  TriangleBackground as AuditAwareBackground,
  StickyFooter,
  CustomAppBar,
} from "auditaware-ui";
import useConfig from "../../hooks/useConfig";

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { dashboardUrl } = useConfig();

  return (
    <>
      <CustomAppBar
        homeLink={dashboardUrl}
        appName="SSO Portal"
      />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundImage: `url(${AuditAwareBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "103% auto",
          backgroundPosition: "bottom -85px left -10px",
          paddingTop: "64px",
          minHeight: "calc(100vh - 68px)", // Subtract the app bar height
        }}
      >
        <Box
          sx={{
            flex: 1,
            p: "16px",
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
      <StickyFooter />
    </>
  );
};

export default Layout;
