import { useMemo } from "react";
import { SUBContextProvider } from "@reasongcp/react-fire-sub";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useTernaryDarkMode } from "usehooks-ts";
import AppRoutes from "./components/shared/AppRoutes";
import useConfig from "./hooks/useConfig";
import ApolloProvider from "./components/shared/ApolloProvider";
import { DrawerPortalProvider } from "auditaware-ui";

const generateTheme = (mode: "light" | "dark") => {
  return createTheme({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            primary: {
              light: "#9AAAEA",
              main: "#3c4b86",
              dark: "#272b3f",
            },
            background: {
              default: "#F5F5F5",
              paper: "#fafafa",
            },
          }
        : {
            // palette values for dark mode
            primary: {
              main: "#9AAAEA",
              dark: "#7A89C3",
            },
          }),
    },
  });
};

function App() {
  const darkTheme = useMemo(() => generateTheme("dark"), []);
  const lightTheme = useMemo(() => generateTheme("light"), []);
  const { firebaseConfig, fireSubApi, authCookieName, useFirebaseEmulator } =
    useConfig();
  const { isDarkMode } = useTernaryDarkMode();

  return (
    <SUBContextProvider
      config={firebaseConfig}
      authCookieName={authCookieName}
      emulate={useFirebaseEmulator}
    >
      <ApolloProvider uri={fireSubApi}>
        <BrowserRouter>
          <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <CssBaseline />
            <DrawerPortalProvider>
              <AppRoutes />
            </DrawerPortalProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ApolloProvider>
    </SUBContextProvider>
  );
}

export default App;
