import { config as firebaseQa } from "./qa.firebaseConfig";
import { config as firebaseProduction } from "./production.firebaseConfig";

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export interface EnvConfig {
  baseUri: string;
  clientUri: string;
  firebaseConfig: FirebaseConfig;
  fireSubApi: string;
  caseManagerUrl: string;
  digitalCanvasUrl: string;
  userManagementUrl: string;
  dashboardUrl: string;
}

export type Environments = "production" | "qa" | "preview" | "development";

const config: Record<Environments, EnvConfig> = {
  production: {
    firebaseConfig: firebaseProduction,
    baseUri: "",
    clientUri: "",
    fireSubApi: "https://sso-api.auditaware.app/",
    caseManagerUrl: "https://case.auditaware.app",
    digitalCanvasUrl: "https://canvas.auditaware.app",
    userManagementUrl: "https://sso.auditaware.app/users",
    dashboardUrl: "https://sso.auditaware.app",
  },
  qa: {
    firebaseConfig: firebaseQa,
    baseUri: "",
    clientUri: "",
    fireSubApi: "https://qa-sso-api.auditaware.app/",
    caseManagerUrl: "https://qa-case.auditaware.app",
    digitalCanvasUrl: "https://qa-canvas.auditaware.app",
    userManagementUrl: "https://qa-sso.auditaware.app/users",
    dashboardUrl: "https://qa-sso.auditaware.app",
  },
  preview: {
    firebaseConfig: firebaseQa,
    baseUri: "",
    clientUri: "",
    fireSubApi: "https://qa-sso-api.auditaware.app/",
    caseManagerUrl: "https://qa-case.auditaware.app",
    digitalCanvasUrl:  "https://qa-canvas.auditaware.app",
    userManagementUrl: "https://qa-sso.auditaware.app/users",
    dashboardUrl: "https://qa-sso.auditaware.app",
  },
  development: {
    firebaseConfig: {
      ...firebaseQa,
      projectId: "auditaware-dev",
    },
    baseUri: "http://localhost:6002",
    clientUri: "http://localhost:6002",
    fireSubApi: "http://localhost:4001/",
    caseManagerUrl: "http://case.apps.localhost:6010",
    digitalCanvasUrl: "http://localhost:19006",
    userManagementUrl: "http://sso.apps.localhost:6002/users",
    dashboardUrl: "http://sso.apps.localhost:6002",
  },
};

export default config;
