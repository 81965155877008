import { Box } from "@mui/material";
import { ChangeEvent, FC, useCallback } from "react";
import { Search, SearchIconWrapper, StyledInputBase } from "./SearchBoxStyles";
import SearchIcon from "@mui/icons-material/Search";

interface SearchBoxProps {
  setSearch: (search: string) => void;
}

const SearchBox: FC<SearchBoxProps> = ({ setSearch }) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [setSearch]
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 2,
        alignItems: "center",
      }}
    >
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          onChange={handleChange}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
        />
      </Search>
    </Box>
  );
};

export default SearchBox;
