import { useMemo } from "react";
import envConfig, { EnvConfig, Environments } from "../config";

const detectStage = (hostname: string): Environments => {
  /* eslint no-useless-escape: 0 */
  const previewRegExp = /([^ ]*qa-sso--pr[0-9]{1,3}\-.+?\.\web\.app)/;

  if (hostname === "sso.auditaware.app") {
    return "production";
  }

  if (hostname === "qa-sso.auditaware.app") {
    return "qa";
  }

  if (hostname.match(previewRegExp)) {
    return "preview";
  }

  return "development";
};

interface Config extends EnvConfig {
  releaseStage: Environments;
  devMode: boolean;
  useFirebaseEmulator: boolean;
  authCookieName: string;
}

export default function useConfig(): Config {
  const { hostname } = window.location;

  return useMemo(() => {
    const releaseStage = detectStage(hostname);
    const useFirebaseEmulator = releaseStage === "development";
    const devMode = ["qa", "preview", "development"].indexOf(releaseStage) >= 0;
    const authCookieName = `auditaware_auth_${releaseStage}`;

    return {
      ...envConfig[releaseStage],
      releaseStage,
      devMode,
      useFirebaseEmulator,
      authCookieName,
    };
  }, [hostname]);
}
